<template>
    <div class="primary-section-content pb-2" v-if="properties.length > 0">

        <hb-breadcrumb v-if="selectedBatch && !previousBatchName && !loading" @click="goBack()">
            Back to Notice Manager
        </hb-breadcrumb>
      <hb-breadcrumb v-if="selectedBatch && previousBatchName && !loading" @click="goToPreviousRow()">
        Back to {{ previousBatchName }}
      </hb-breadcrumb>

        <hb-header full v-if="!loading">
            <hb-page-header
                v-if="selectedBatch && property"
                :description="property.number + ' - ' + property.Address.city + ' - ' + property.Address.address"
            >
                <template v-slot:title>
                    {{ selectedBatchDetails.batch_info.template && selectedBatchDetails.batch_info.template.name ? selectedBatchDetails.batch_info.template.name : '-' }} <span v-if="selectedBatchDetails.batch_info.batch_created_at" class="pl-1 font-weight-regular">- Batch Created: {{ selectedBatchDetails.batch_info.batch_created_at | formatDateTimeCustom('MMM DD, YYYY') }}</span>
                </template>
            </hb-page-header>
            <hb-page-header
                v-else-if="!selectedBatch"
                title="Notice Manager"
                description="View, download, and manage the documents you've received or created within Hummingbird."
            >
            </hb-page-header>
        </hb-header>

        <HbPropertySelector
            v-if="properties.length > 1 && !selectedBatch"
            v-model="property"
            :items="properties"
            :loading="loadingBatches"
            :disabled="loadingBatches"
            item-text="name"
            item-value="id"
            return-object
        />

        <div v-if="properties.length > 1 && !property && !selectedBatch" class="dlpc-content-section-wrapper">
            <hb-empty-state
                message="Select a Property to view Document Batches."
                :showBtn="false"
            ></hb-empty-state>
        </div>

        <div class="dlpc-content-section-wrapper" v-else>

            <div v-if="selectedBatch && loading" class="dlpc-loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>

            <div v-if="selectedBatch && !loading && selectedBatchDetails.batch_info" class="pt-2 pb-1">
                <span class="font-weight-medium">
                    Delivery Method: <span class="font-weight-regular hb-text-light">{{ selectedBatchDetails.batch_info.delivery_method ? selectedBatchDetails.batch_info.delivery_method : '-' }}</span> / Delivered By: <span class="font-weight-regular hb-text-light">{{ selectedBatchDetails.batch_info.mailhouse && (selectedBatchDetails.batch_info.mailhouse == 'RPost' || selectedBatchDetails.batch_info.mailhouse == 'Rpost' || selectedBatchDetails.batch_info.mailhouse == 'Simple Certified') ? selectedBatchDetails.batch_info.mailhouse + '&trade;' : selectedBatchDetails.batch_info.mailhouse ? selectedBatchDetails.batch_info.mailhouse : '-' }}</span>
                    <span v-if="selectedBatchDetails.batch_info.mailhouse == 'Simple Certified'" style="position:relative;top:-1px;">
                        <hb-tooltip max-width="200">
                            <template v-slot:body>
                                In the event of a tenant filing a lawsuit, you will need to download the Firm Book from the Simple Certified direct login.
                            </template>
                        </hb-tooltip>
                    </span>
                </span>
            </div>

            <div
                v-if="selectedBatch && selectedBatchDetails.batch_info.delivery_method && selectedBatchDetails.batch_info.delivery_method.includes('First Class Mail') && !loading"
                class="mt-3"
            >
                <hb-notification
                    type="caution"
                    title-off
                    not-dismissable
                >
                    When sending First Class mail, users should double-check their tenants' addresses to verify that they are current and up-to-date.
                </hb-notification>
            </div>
          <div v-if="selectedBatch && selectedBatchDetails?.batch_info?.retry_batch_id && !loading"
               class="mt-3"
          >
            <hb-notification
                type="guidance"
                not-dismissable=true

            >
              This is a Resent Batch. Click to see the original batch.
              <template v-slot:condensedActions>
                <hb-menu
                    options
                    options-hover-type="guidance"
                >
                  <v-list>
                    <v-list-item :ripple="false">
                      <v-list-item-title>View Original Batch</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </hb-menu>
              </template>
              <template v-slot:actions>
                <hb-btn small color="secondary" @click="goToOriginalRow(selectedBatchDetails?.batch_info)">View Original Batch</hb-btn>
              </template>
            </hb-notification>
          </div>



          <hb-modal
              v-model="retryBatchModal"
              title="Notice"
              size="medium"
              confirmation
              show-help-link
          >
            <template v-slot:content>
              <div class="pa-4">
               You are about to resend <span class="hb-font-body-medium">{{ Math.abs((retryRow?.interaction_status_count.error || 0) - (retryRow?.interaction_status_count.resolved || 0)) || 'all'}}</span> notices from batch:  <span class="hb-font-body-medium">{{retryRow?.template?.name}}</span>
                <br /><br />
                <span class="hb-font-body-medium">Resending will likely fail if the issues causing the errors have not been resolved.</span>
                <br /><br />
                Also, confirm that the timing of these notices adheres to your state's regulatory requirements, especially regarding any legal notices
              </div>
            </template>

            <template v-slot:right-actions>
              <hb-btn
                  color="primary"
                  @click="retryBatchSend"
              >
                Resend Failed Documents
              </hb-btn>
            </template>
          </hb-modal>

          <hb-modal
              v-model="retryDocumentModal"
              title="Notice"
              size="medium"
              confirmation
              show-help-link
          >
            <template v-slot:content>
              <div class="pa-4">
                You are about to resend a failed notice for tenant <span class="hb-font-body-medium">{{retryRow?.contact?.first_name}} {{retryRow?.contact?.last_name}}</span> in space <span class="hb-font-body-medium">{{retryRow?.unit?.number}}</span>
                <br /><br />
                <span class="hb-font-body-medium">Resending will likely fail if the issues causing the errors have not been resolved.</span>
                <br /><br />
                Also, confirm that the timing of these notices adheres to your state's regulatory requirements, especially regarding any legal notices
              </div>
            </template>

            <template v-slot:right-actions>
              <hb-btn
                  color="primary"
                  @click="retryDocumentSend"
              >
                Resend Failed Documents
              </hb-btn>
            </template>
          </hb-modal>


            <hb-data-table-header class="mt-1" left-columns="6" right-columns="6" v-if="!loading">
                <template v-slot:description>
                    <HbTextField
                        v-if="selectedBatch"
                        search
                        v-model="batchSearch"
                        placeholder="Search"
                        style="max-width:300px;"
                        @input="searchBatch"
                        :disabled="loadingBatch"
                    />
                    <HbTextField
                        v-else
                        search
                        v-model="search"
                        placeholder="Search"
                        style="max-width:300px;"
                        @input="searchBatches"
                        :disabled="loadingBatches"
                    />
                </template>
                <template v-slot:actions>
                    <span v-if="selectedBatch">
                        <hb-modal
                            v-model="filterBatchModal"
                            title="Filter Batch Items"
                            show-help-link
                        >
                            <template v-slot:activator>
                                <hb-btn icon :active="filterBatchModal" tooltip="Filter">mdi-filter-variant</hb-btn>
                            </template>
                            <template v-slot:subheader>
                                Select the options you would like to filter by
                            </template>
                            <template v-slot:content>
                                <hb-form label="Contact Type">
                                    <HbCheckbox v-model="filterBatchOptionsClone.contact_type.primary.value" :label="filterBatchOptionsClone.contact_type.primary.name" :false-value="false" />
                                    <HbCheckbox v-model="filterBatchOptionsClone.contact_type.alternate.value" :label="filterBatchOptionsClone.contact_type.alternate.name" :false-value="false" />
                                    <HbCheckbox v-model="filterBatchOptionsClone.contact_type.lien_holder.value" :label="filterBatchOptionsClone.contact_type.lien_holder.name" :false-value="false" />
                                </hb-form>
                                <hb-form label="Document Status" :divider="false">
                                    <span v-if="selectedBatchDetails.batch_info.delivery_method == 'Certified Mail' || selectedBatchDetails.batch_info.delivery_method == 'Certified Mail with Electronic Return Receipt' || selectedBatchDetails.batch_info.delivery_method == 'First Class Mail' || selectedBatchDetails.batch_info.delivery_method == 'First Class Mail with Certificate of Mailing'">
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.error.value" :label="filterBatchOptionsClone.document_status.error.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.generated.value" :label="filterBatchOptionsClone.document_status.generated.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.pending.value" :label="filterBatchOptionsClone.document_status.pending.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.sent.value" :label="filterBatchOptionsClone.document_status.sent.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.resolved.value" :label="filterBatchOptionsClone.document_status.resolved.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.retried.value" :label="filterBatchOptionsClone.document_status.retried.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.delivered.value" :label="filterBatchOptionsClone.document_status.delivered.name" :false-value="false" />
                                    </span>
                                    <span v-else-if="selectedBatchDetails.batch_info.delivery_method ==  rpost_delivery_method">
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.error.value" :label="filterBatchOptionsClone.document_status.error.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.bounced.value" :label="filterBatchOptionsClone.document_status.bounced.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.pending.value" :label="filterBatchOptionsClone.document_status.pending.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.sent.value" :label="filterBatchOptionsClone.document_status.sent.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.resolved.value" :label="filterBatchOptionsClone.document_status.resolved.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.retried.value" :label="filterBatchOptionsClone.document_status.retried.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.opened.value" :label="filterBatchOptionsClone.document_status.opened.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.delivered.value" :label="filterBatchOptionsClone.document_status.delivered.name" :false-value="false" />
                                    </span>
                                    <span v-else-if="selectedBatchDetails.batch_info.delivery_method == 'Standard Email'">
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.sent.value" :label="filterBatchOptionsClone.document_status.sent.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.error.value" :label="filterBatchOptionsClone.document_status.error.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.generated.value" :label="filterBatchOptionsClone.document_status.generated.name" :false-value="false" />
                                      <HbCheckbox v-model="filterBatchOptionsClone.document_status.retried.value" :label="filterBatchOptionsClone.document_status.retried.name" :false-value="false" />
                                    </span>
                                    <span v-else>
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.error.value" :label="filterBatchOptionsClone.document_status.error.name" :false-value="false" />
                                        <HbCheckbox v-model="filterBatchOptionsClone.document_status.generated.value" :label="filterBatchOptionsClone.document_status.generated.name" :false-value="false" />
                                    </span>
                                </hb-form>
                            </template>
                            <template v-slot:left-actions>
                                <hb-link v-if="hasBatchFilters && !loadingBatch" @click="resetBatchFilters()">Reset Filters</hb-link>
                            </template>
                            <template v-slot:right-actions>
                                <hb-btn @click="goBatchFilter()">Filter</hb-btn>
                            </template>
                        </hb-modal>
                        <hb-btn
                            color="secondary"
                            @click="getCombinedDocuments(selectedBatchDetails.batch_info.combined_document.id, selectedBatchDetails.batch_info.document_batch_id)"
                            class="pl-2"
                        >
                            View / Print All Documents
                        </hb-btn>
                    </span>
                    <hb-modal
                        v-model="filterModal"
                        title="Filter Document Batches"
                        show-help-link
                        v-else
                    >
                        <template v-slot:activator>
                            <hb-btn icon :active="filterModal" tooltip="Filter">mdi-filter-variant</hb-btn>
                        </template>
                        <template v-slot:subheader>
                            Select the options you would like to filter by
                        </template>
                        <template v-slot:content>
                            <hb-form label="Status">
                                <HbCheckbox v-model="filterOptionsClone.status.error.value" :label="filterOptionsClone.status.error.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.status.in_progress.value" :label="filterOptionsClone.status.in_progress.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.status.completed.value" :label="filterOptionsClone.status.completed.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.status.print_mail.value" :label="filterOptionsClone.status.print_mail.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.status.retried.value" :label="filterOptionsClone.status.retried.name" :false-value="false" />
                            </hb-form>
                            <hb-form label="Document Type">
                                <HbCheckbox v-model="filterOptionsClone.document_type.delinquency.value" :label="filterOptionsClone.document_type.delinquency.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.document_type.rent_change.value" :label="filterOptionsClone.document_type.rent_change.name" :false-value="false" />
                            </hb-form>
                            <hb-form label="Delivery Method">
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.email.value" :label="filterOptionsClone.delivery_method.email.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.registered_email.value" :label="filterOptionsClone.delivery_method.registered_email.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.first_class.value" :label="filterOptionsClone.delivery_method.first_class.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.first_class_with_certificate_of_mailing.value" :label="filterOptionsClone.delivery_method.first_class_with_certificate_of_mailing.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.certified_mail.value" :label="filterOptionsClone.delivery_method.certified_mail.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.certified_mail_with_electronic_return_receipt.value" :label="filterOptionsClone.delivery_method.certified_mail_with_electronic_return_receipt.name" :false-value="false" />
                                <HbCheckbox v-model="filterOptionsClone.delivery_method.no_delivery_requested.value" :label="filterOptionsClone.delivery_method.no_delivery_requested.name" :false-value="false" />
                            </hb-form>
                            <hb-form
                                label="Date Range"
                                description="The date range is based on when the document batches were generated."
                                :divider="false"
                            >
                                <v-row no-gutters class="my-n1">Select a start and end date.</v-row>
                                <v-row no-gutters>
                                    <v-col cols="6" no-gutters>
                                        <hb-date-picker
                                            v-model="filterOptionsClone.date_range.start_date.value"
                                            @click:clear="filterOptionsClone.date_range.start_date.value = null"
                                            label="Select Start Date"
                                            :max="filterOptionsClone.date_range.end_date.value ? filterOptionsClone.date_range.end_date.value : todaysDate"
                                            :single-line="true"
                                            :clearable="true"
                                            :solo="false"
                                            data-vv-name="start_date"
                                            data-vv-as="Start Date"
                                        ></hb-date-picker>
                                    </v-col>
                                    <v-col cols="6" no-gutters class="pl-3">
                                        <hb-date-picker
                                            v-model="filterOptionsClone.date_range.end_date.value"
                                            @click:clear="filterOptionsClone.date_range.end_date.value = null"
                                            label="Select End Date"
                                            :max="todaysDate"
                                            :min="filterOptionsClone.date_range.start_date.value ? filterOptionsClone.date_range.start_date.value : ''"
                                            :single-line="true"
                                            :clearable="true"
                                            :solo="false"
                                            data-vv-name="end_date"
                                            data-vv-as="End Date"
                                        ></hb-date-picker>
                                    </v-col>
                                </v-row>
                            </hb-form>
                        </template>
                        <template v-slot:left-actions>
                            <hb-link v-if="hasFilters && !loadingBatches" @click="resetFilters()">Reset Filters</hb-link>
                        </template>
                        <template v-slot:right-actions>
                            <hb-btn @click="goFilter()">Filter</hb-btn>
                        </template>
                    </hb-modal>
                </template>
            </hb-data-table-header>

            <div v-if="selectedBatch && !loading">
                <hb-data-table
                    :headers="batchHeaders"
                    :items="selectedBatchDetails.documents"
                    :loading="loadingBatch"
                    loading-text="Loading Batch..."
                    class="mt-1 mb-3"
                    @current-items="currentBatchItems = $event"
                >

                    <template v-slot:header.document_status="{ header }">
                        {{ header.text }}
                        <span style="position:relative;top:-1px;left:-2px;">
                            <hb-tooltip max-width="400" v-if="selectedBatchDetails.batch_info.mailhouse == 'Simple Certified'">
                                <template v-slot:body>
                                    <div class="font-weight-medium pb-1">Error:</div>
                                    <div class="pb-1">This status is displayed when an error has occurred in the generation or delivery of the document</div>
                                    <div class="font-weight-medium pb-1">Pending:</div>
                                    <div class="pb-1">This status is displayed when the document is generated and sent to Simple Certified</div>
                                    <div class="font-weight-medium pb-1">Sent:</div>
                                    <div class="pb-1">This status is displayed when the document has been sent from the Mailhouse (USPS) facility to the customer</div>
                                    <div class="font-weight-medium pb-1">Delivered:</div>
                                    <div class="pb-1">This status is displayed when the document is delivered by the Mailhouse (USPS) to the customer</div>
                                    <div class="font-weight-medium pb-1">Resolved:</div>
                                    <div class="pb-1">This status is displayed when a manager enters a note and manually marks an error as resolved in the Notes section</div>
                                    <div class="font-weight-medium pb-1">Retried:</div>
                                    <div class="pb-1">This status is displayed when a document has been resent as part of a different batch</div>
                                </template>
                            </hb-tooltip>
                            <hb-tooltip max-width="400" v-else-if="selectedBatchDetails.batch_info.mailhouse == 'RMail® by RPost™'">
                                <template v-slot:body>
                                    <div class="font-weight-medium pb-1">Error:</div>
                                    <div class="pb-1">This status is displayed when an error has occurred in the generation or delivery of the document</div>
                                    <div class="font-weight-medium pb-1">Bounced:</div>
                                    <div class="pb-1">This status is displayed when the {{ rpost_delivery_method ? rpost_delivery_method : 'Registered Email™' }} is bounced</div>
                                    <div class="font-weight-medium pb-1">Pending:</div>
                                    <div class="pb-1">This status is displayed when the Document Generation and delivery is in progress</div>
                                    <div class="font-weight-medium pb-1">Sent:</div>
                                    <div class="pb-1">This status is displayed when the document has been sent to the Mailhouse from RPost</div>
                                    <div class="font-weight-medium pb-1">Delivered:</div>
                                    <div class="pb-1">This status is displayed when the Document Generation and delivery is Completed to the Tenant</div>
                                    <div class="font-weight-medium pb-1">Opened:</div>
                                    <div class="pb-1">This status is displayed when the delivered document is opened by the Tenant</div>
                                    <div class="font-weight-medium pb-1">Resolved:</div>
                                    <div class="pb-1">This status is displayed when a manager enters a note and manually marks an error as resolved in the Notes section</div>
                                    <div class="font-weight-medium pb-1">Retried:</div>
                                    <div class="pb-1">This status is displayed when a document has been resent as part of a different batch</div>
                                </template>
                            </hb-tooltip>
                            <hb-tooltip max-width="400" v-else-if="selectedBatchDetails.batch_info.delivery_method == 'Standard Email'">
                                <template v-slot:body>
                                    <div class="font-weight-medium pb-1">Sent:</div>
                                    <div class="pb-1">The email has been sent successfully</div>
                                    <div class="font-weight-medium pb-1">Error:</div>
                                    <div class="pb-1">This status is displayed when an error has occurred in the generation of the document</div>
                                    <div class="font-weight-medium pb-1">Generated:</div>
                                    <div class="pb-1">This status is displayed when the document was successfully generated</div>
                                </template>
                            </hb-tooltip>
                            <hb-tooltip max-width="400" v-else>
                                <template v-slot:body>
                                    <div class="font-weight-medium pb-1">Error:</div>
                                    <div class="pb-1">This status is displayed when an error has occurred in the generation of the document</div>
                                    <div class="font-weight-medium pb-1">Generated:</div>
                                    <div class="pb-1">This status is displayed when the document was successfully generated</div>
                                </template>
                            </hb-tooltip>
                        </span>
                    </template>
                    
                    <template v-slot:item.space="{ item }">
                        <div class="d-flex align-center">
                            <hb-unit-icon small class="pr-1" color="#101318" :type="getUnitTypeLabel(item.unit)" />
                            <div>{{ item.unit.number }}</div>
                        </div>
                    </template>

                    <template v-slot:item.name="{ item }">
                        <hb-tooltip>
                            <template v-slot:item>
                                <hb-link
                                    :to="'/contacts/' + item.contact.id"
                                    color="secondary"
                                    dashed-underline
                                >
                                    {{ item.contact.first_name }} {{ item.contact.last_name }}
                                </hb-link>
                            </template>
                            <template v-slot:header>
                                <span v-if="batchIsEmail">{{ item.contact.email }}</span>
                                <span v-else>{{ item.contact.address.address1 }}<span v-if="item.contact.address.address2" class="pl-1">{{ item.contact.address.address2 }}</span>, {{ item.contact.address | formatAddress }}</span>
                            </template>
                            <template v-slot:body>
                                {{ batchIsEmail ? 'Current Email Address on Record' : 'Current Address on Record' }}
                            </template>
                        </hb-tooltip>
                    </template>

                    <template v-slot:item.contact_type="{ item }">
                        <span class="text-capitalize">{{ item.contact.type && item.contact.type.length ? item.contact.type : '' }}</span>
                    </template>

                    <template v-slot:item.tracking_number="{ item }">
                        {{ item.meta.tracking_number ? item.meta.tracking_number : '' }}
                    </template>

                    <template v-slot:item.document_status="{ item }">
                        <HbStatusGeneral v-if="item.status" :status="item.resolved ? 'Resolved' : item.status" />
                        <span v-else></span>
                    </template>

                    <template v-slot:item.status_details="{ item }">
                        <div class="py-3">{{ item.resolved ? 'This was resolved by the manager / operator' : item.status_details ? item.status_details : '' }}</div>
                    </template>

                    <template v-slot:item.notes="{ item }">
                        <hb-link @click="openNotesModal(item)">{{ item.note_count == 0 ? '+ Add Notes' : 'View Notes' }}</hb-link>
                    </template>
                    
                    <template v-slot:item.actions="{ item }">
                        <hb-menu
                            options
                            align-right
                        >
                            <v-list>
                                <v-list-item :ripple="false" :disabled="!item.generated_document.id" @click="getIndividualDocument(item.generated_document.id)">
                                    <v-list-item-title>
                                        View / Print Document
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" :disabled="!item.generated_document.id" v-if="item.status == 'error' && canRetry && item.resolved === 0" @click="confirmRetryDocumentModal(item)">
                                    <v-list-item-title>
                                        Retry Send
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.meta.acceptance_document_refid" @click="getMetaDocument(item.meta.acceptance_document_refid)">
                                    <v-list-item-title>
                                        View / Print Acceptance Document
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.meta.delivery_document_refid" @click="getMetaDocument(item.meta.delivery_document_refid)">
                                    <v-list-item-title>
                                        View / Print Delivery Document
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.meta.delivery_receipt_refid" @click="getMetaDocument(item.meta.delivery_receipt_refid)">
                                    <v-list-item-title>
                                        View / Print Delivery Receipt
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.meta.electronic_return_receipt_refid" @click="getMetaDocument(item.meta.electronic_return_receipt_refid)">
                                    <v-list-item-title>
                                        View / Print Electronic Return Receipt
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.meta.xml_receipt_refid" @click="getMetaDocument(item.meta.xml_receipt_refid)">
                                    <v-list-item-title>
                                        View / Print XML Receipt
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>

                    <template v-slot:footer>
                        <v-divider></v-divider>
                        <v-row no-gutters class="px-4 dlpc-table-footer-wrapper">
                            
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 8 : 10" no-gutters class="d-flex align-top">
                                <div class="hb-font-caption pr-1 pt-4" style="min-width: 120px;">
                                    Documents: <span class="font-weight-medium">{{ currentBatchItems.length }}</span>
                                </div>
                                <div class="dlpc-display-inline py-2" style="position:relative;top:.5px;">
                                    <div
                                        v-for="item in filterBatchOptions.contact_type"
                                        :key="'contact_type_filter' + item.name"
                                        class="dlpc-display-inline"
                                    >
                                        <hb-chip
                                            v-if="item.value"
                                            :editable="!loadingBatch"
                                            @click="goBatchFilter('contact_type', item.param)"
                                        >
                                            {{ item.name }}
                                        </hb-chip>
                                    </div>
                                    <div
                                        v-for="item in filterBatchOptions.document_status"
                                        :key="'document_status_filter' + item.name"
                                        class="dlpc-display-inline"
                                    >
                                        <hb-chip
                                            v-if="item.value"
                                            :editable="!loadingBatch"
                                            @click="goBatchFilter('document_status', item.param)"
                                        >
                                            {{ item.name }}
                                        </hb-chip>
                                    </div>
                                </div>
                            </v-col>
                            <v-col v-if="hasBatchFilters && !loadingBatch" :cols="$vuetify.breakpoint.smAndDown ? 4 : 2" no-gutters class="py-3" align="right">
                                <hb-btn small color="secondary" @click="resetBatchFilters()">Reset Filters</hb-btn>
                            </v-col>
                        </v-row>
                    </template>
                </hb-data-table>

                <div v-if="loadingBatch && selectedBatchDetails && selectedBatchDetails.documents && selectedBatchDetails.documents.length > 0" class="d-flex justify-center ma-0 pa-0 mt-n3 mb-n2" style="min-height:50px;">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>

                <div v-if="allowBatchScrollRequest && !loadingBatch" v-intersect="checkBatchIfIntersecting" class="d-flex justify-center ma-0 pa-0 mt-n3 mb-n2" style="min-height:1px;">
                </div>

                <hb-modal
                    v-model="notesModal"
                    :title="notesModalView == 'add' ? 'Add Notes' : 'View Notes'"
                    :close-instead-of-cancel="notesModalView !== 'add'"
                    @close="closeNotesModal()"
                    show-help-link
                >
                    <template v-slot:subheader>
                        Add notes relating to the tenant's document.
                    </template>
                    <template v-slot:content>
                        <div class="pa-0">
                            <div class="mb-n2 mt-2">
                                <HbTextarea
                                    v-if="notesModalView == 'add'"
                                    v-model="notesModalTextarea"
                                    v-validate.disable="'required|max:1000'"
                                    placeholder="Enter Note"
                                    name="note"
                                    data-vv-as="Note"
                                    :error="errors.collect('note').length > 0"
                                    :rows="selectedDocumentNotes.length == 0 ? 16 : 13"
                                    class="pt-1 px-4"
                                    autofocus
                                    @click="activeIndex = null"
                                />
                                <v-divider
                                    v-if="notesModalView == 'add' && selectedDocumentNotes.length > 0"
                                    class="mt-4"
                                ></v-divider>
                            </div>
                            <div class="dlpc-notes-modal-content dlpc-notes-modal-notes" v-if="selectedDocumentNotes.length">
                                <div
                                    v-for="(note, i) in selectedDocumentNotes"
                                    :key="'note_' + note.id"
                                    class="px-3 pt-3"
                                    :class="{ 'pb-3' : i == selectedDocumentNotes.length - 1 }"
                                >
                                    <hb-communication
                                        type="note"
                                        :admin-name="note.last_modified_by.first + ' ' + note.last_modified_by.last"
                                        :time="note.created | momentNotesTime"
                                        :active="i == activeIndex"
                                        :expanded.sync="note.expanded"
                                        @click="activeIndex = i"
                                        disable-pinning
                                    >
                                        <!-- {{ note.content }} -->
                                        <div v-html="note.content"></div>
                                    </hb-communication>
                                </div>
                            </div>
                            <div v-else class="my-1">
                                &nbsp;
                            </div>
                        </div>
                    </template>
                    <template v-slot:secondary v-if="selectedDocumentIsErrorOrResolved">
                        <v-divider></v-divider>
                        <div class="py-2 px-3">
                            <hb-checkbox v-model="notesModalResolvedCheckbox" label="Mark document as Resolved."></hb-checkbox>
                        </div>
                    </template>
                    <template v-slot:right-actions>
                        <hb-btn v-if="notesModalAllowAdd" color="secondary" @click="notesModalAdd()">+ Add Note</hb-btn>
                        <hb-btn v-if="notesModalAllowSave || (notesModalResolvedStateChanged && selectedDocumentNotes.length > 0)" @click="notesModalSave()" :disabled="!notesModalTextarea.length && notesModalAddingNoteInProgress">Save</hb-btn>
                    </template>
                </hb-modal>

            </div>

            <div v-else-if="!selectedBatch">

                <hb-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loadingBatches"
                    loading-text="Loading Batches..."
                    class="mt-1 mb-3"
                    @current-items="currentItems = $event"
                    @click:row="rowClicked"
                >

                  <template v-slot:header.document_batch="{ header }">
                    {{ header.text }}
                    <span style="position:relative;top:-1px;left:-2px;">
                            <hb-tooltip max-width="400">
                                <template v-slot:body>

                                    <div class="font-weight-medium pb-1"><hb-icon class="pr-1" color="#ffffff" mdi-code="mdi-file-restore" style="position:relative;top:-2px"></hb-icon>Re-sent Batch</div>
                                    <div class="pb-1">A batch of notices that were re-sent due to their failure in a previous batch</div>
                                    <div class="font-weight-medium pb-1"><hb-icon class="pr-1" color="#ffffff" mdi-code="mdi-file" style="position:relative;top:-2px"></hb-icon>Original Batch</div>
                                    <div class="pb-1">A batch of notices that was automatically generated and sent</div>
                                </template>
                            </hb-tooltip>
                        </span>
                  </template>

                    <template v-slot:header.derived_status="{ header }">
                        {{ header.text }}
                        <span style="position:relative;top:-1px;left:-2px;">
                            <hb-tooltip max-width="400">
                                <template v-slot:body>
                                    <div class="font-weight-medium pb-1">Error:</div>
                                    <div class="pb-1">This status is displayed when an error has occured</div>
                                    <div class="font-weight-medium pb-1">In Progress:</div>
                                    <div class="pb-1">This status is displayed when the Document Generation and delivery is in progress</div>
                                    <div class="font-weight-medium pb-1">Completed:</div>
                                    <div class="pb-1">This status is displayed when the Document Generation and delivery is Completed</div>
                                    <div class="font-weight-medium pb-1">Print &amp; Mail:</div>
                                    <div class="pb-1">This status is displayed when the Document Generation is Manually done by the Manager</div>
                                </template>
                            </hb-tooltip>
                        </span>
                    </template>

                    <template v-slot:item.document_batch="{ item }">
                      <div v-if="item.retry_batch_id"><hb-icon small class="pr-1" color="#000000" mdi-code="mdi-file-restore" style="position:relative;top:-2px"></hb-icon>{{ item.template.name }}</div>
                      <div v-else ><hb-icon small class="pr-1" color="#000000" mdi-code="mdi-file" style="position:relative"></hb-icon>{{ item.template.name }}</div>

                        <div v-if="item.document_type" class="hb-text-light">{{ item.document_type }}</div>
                        <div v-else></div>
                    </template>

                    <template v-slot:item.delivery="{ item }">
                        {{ item.delivery.method && item.delivery.method.name ? item.delivery.method.name : '' }}
                    </template>

                    <template v-slot:item.delivered_by="{ item }">
                        <span class="font-weight-medium" v-if="item.delivery.mailhouse">{{ item.delivery.mailhouse }}</span>
                        <span v-else></span>
                    </template>

                    <template v-slot:item.created_on="{ item }">
                        {{ item.created_at | formatDateTimeCustom('MMM DD, YYYY [@] h:mma') }}
                    </template>

                    <template v-slot:item.errors="{ item }">
                        <hb-tooltip v-if="parseInt(item.interaction_status_count.error - item.interaction_status_count.resolved) > 0 || parseInt(item.interaction_status_count.bounced) > 0">
                            <template v-slot:item>
                                <div class="hb-text-error dashed-underline" @mouseover="getErrors(item)">{{ parseInt(item.interaction_status_count.error - item.interaction_status_count.resolved) + parseInt(item.interaction_status_count.bounced) }}</div>
                            </template>
                            <template v-slot:body>
                                <div v-if="batchErrorItemsLoading" class="d-flex justify-center mx-0 pa-0 my-n4" style="min-height:48px;">
                                    <v-progress-circular
                                        indeterminate
                                        color="white"
                                        size="22"
                                        width="2"
                                    ></v-progress-circular>
                                </div>
                                <div v-else-if="batchErrorItems.length">
                                    <div v-for="(error, i) in batchErrorItems" :key="'batch_error' + item.document_batch_id + '_' + i">
                                        <hb-unit-icon small class="pr-1" color="#ffffff" :type="getUnitTypeLabel(error.unit)" />
                                        <span class="white--text">{{ error.unit.number }} - {{ error.contact.first_name }} {{error.contact.last_name}}</span>
                                    </div>
                                    <div v-if="(parseInt(item.interaction_status_count.error) + parseInt(item.interaction_status_count.bounced)) > 3" class="d-flex justify-end">+ {{ (parseInt(item.interaction_status_count.error) + parseInt(item.interaction_status_count.bounced)) - 3 }} more</div>
                                </div>
                            </template>
                        </hb-tooltip>
                        <span v-else></span>
                    </template>

                    <template v-slot:item.derived_status="{ item }">
                        <HbStatusGeneral v-if="item.derived_status == 'error' || item.derived_status == 'generated' || item.derived_status == 'pending' || item.derived_status == 'retried' || item.derived_status == 'completed'" :status="(item.derived_status == 'error' && item.interaction_status_count.resolved == item.interaction_status_count.error && item.interaction_status_count.error > 0) ? 'Resolved' : item.derived_status == 'error' ? 'Error' : item.derived_status == 'generated' ? 'Print & Mail' : item.derived_status == 'pending' ? 'In Progress' : item.derived_status == 'retried' ? 'retried' : item.derived_status == 'completed' ? 'Completed' : item.derived_status" />
                        <span v-else></span>
                    </template>

                    <template v-slot:item.last_downloaded_on="{ item }">
                        <div class="py-3">
                            <div v-if="item.last_downloaded_on">{{ item.last_downloaded_on | formatDateTimeCustom('MMM DD, YYYY [@] h:mma') }}</div>
                            <div v-else></div>
                            <div v-if="item.last_downloaded_by && (item.last_downloaded_by.first_name || item.last_downloaded_by.last_name)" class="hb-text-light mt-1 mb-n1">By: {{ item.last_downloaded_by.first_name }} {{ item.last_downloaded_by.last_name }}</div>
                            <div v-else></div>
                        </div>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <hb-menu
                            options
                            align-right
                        >
                            <v-list>
                                <v-list-item :ripple="false" @click="rowClicked(item)">
                                    <v-list-item-title>
                                        View Batch Details
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" :disabled="!item.combined_document.id" @click="getCombinedDocuments(item.combined_document.id, item.document_batch_id)">
                                    <v-list-item-title>
                                        View / Print Documents
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.derived_status == 'error'" :disabled="!item.combined_document.id" @click="downloadErrorDocs(item, item.document_batch_id)">
                                        <v-list-item-title>
                                            Download Failed Documents
                                        </v-list-item-title>
                                </v-list-item>

                                <v-list-item :ripple="false" v-if="item.derived_status == 'error' && (item.interaction_status_count.resolved != item.interaction_status_count.error && item.interaction_status_count.error > 0) && canRetry" @click="confirmRetryBatchModal(item)">
                                    <v-list-item-title>
                                        Retry Send
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>

                    <template v-slot:footer>
                        <v-divider></v-divider>
                        <v-row no-gutters class="px-4 dlpc-table-footer-wrapper">
                            
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 8 : 10" no-gutters class="d-flex align-top">
                                <div class="hb-font-caption pr-1 pt-4" style="min-width: 160px;">
                                    Document Batches: <span class="font-weight-medium">{{ currentItems.length }}</span>
                                </div>
                                <div class="dlpc-display-inline py-2" style="position:relative;top:.5px;">
                                    <div
                                        v-for="item in filterOptions.status"
                                        :key="'status_filter' + item.name"
                                        class="dlpc-display-inline"
                                    >
                                        <hb-chip
                                            v-if="item.value"
                                            :editable="!loadingBatches"
                                            @click="goFilter('status', item.param)"
                                        >
                                            {{ item.name }}
                                        </hb-chip>
                                    </div>
                                    <div
                                        v-for="item in filterOptions.document_type"
                                        :key="'document_type_filter' + item.name"
                                        class="dlpc-display-inline"
                                    >
                                        <hb-chip
                                            v-if="item.value"
                                            :editable="!loadingBatches"
                                            @click="goFilter('document_type', item.param)"
                                        >
                                            {{ item.name }}
                                        </hb-chip>
                                    </div>
                                    <div
                                        v-for="item in filterOptions.delivery_method"
                                        :key="'delivery_method_filter' + item.name"
                                        class="dlpc-display-inline"
                                    >
                                        <hb-chip
                                            v-if="item.value"
                                            :editable="!loadingBatches"
                                            @click="goFilter('delivery_method', item.param)"
                                        >
                                            {{ item.name }}
                                        </hb-chip>
                                    </div>
                                    <div v-if="filterOptions.date_range.start_date.value || filterOptions.date_range.end_date.value" class="dlpc-display-inline">
                                        <hb-chip
                                            :editable="!loadingBatches"
                                            @click="goFilter('date_range', 'start_date_end_date')"
                                        >
                                            <div class="d-flex align-center">
                                                <div v-if="!filterOptions.date_range.start_date.value" class="pr-1">&le;</div>
                                                <div v-if="!filterOptions.date_range.end_date.value" class="pr-1">&ge;</div>
                                                <div v-if="filterOptions.date_range.start_date.value">{{ filterOptions.date_range.start_date.value | momentUtcStartOfDay }}</div>
                                                <div v-if="filterOptions.date_range.start_date.value && filterOptions.date_range.end_date.value" class="px-1">-</div>
                                                <div v-if="filterOptions.date_range.end_date.value">{{ filterOptions.date_range.end_date.value | momentUtcEndOfDay }}</div>
                                            </div>
                                        </hb-chip>
                                    </div>
                                </div>
                            </v-col>
                            <v-col v-if="hasFilters && !loadingBatches" :cols="$vuetify.breakpoint.smAndDown ? 4 : 2" no-gutters class="py-3" align="right">
                                <hb-btn small color="secondary" @click="resetFilters()">Reset Filters</hb-btn>
                            </v-col>
                        </v-row>
                        
                    </template>

                </hb-data-table>

                <div v-if="loadingBatches && items.length > 0" class="d-flex justify-center ma-0 pa-0 mt-n3 mb-n2" style="min-height:50px;">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>

                <div v-if="allowScrollRequest && !loadingBatches" v-intersect="checkIfIntersecting" class="d-flex justify-center ma-0 pa-0" style="min-height:1px;">
                </div>

            </div>

        </div>
    
    </div>
</template>

<script type="text/babel">
    import HbDatePicker from '../assets/HummingbirdDatepicker';
    import { mapGetters, mapActions } from 'vuex';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import NOTICE_MANAGER from '@/constants/notice_manager.js'; 
    export default {
        name: "NoticeManagerIndex",
		mixins: [ notificationMixin ],
        data() {
            return {
                property: '',
                headers: [
                    { text: 'Document Batch', value: 'document_batch', width: '200px', sortable: false },
                    { text: 'Delivery Method', value: 'delivery', width: '160px', sortable: false },
                    { text: 'Delivered By', value: 'delivered_by', width: '160px', sortable: false },
                    { text: 'Generated On', value: 'created_on', width: '140px', sortable: false },
                    { text: 'Quantity', value: 'quantity', align: 'center', width: '100px', sortable: false },
                    { text: 'Errors', value: 'errors', align: 'center', width: '90px', sortable: false },
                    { text: 'Status', value: 'derived_status', width: '120px', sortable: false },
                    { text: 'Last Downloaded', value: 'last_downloaded_on', width: '190px', sortable: false },
                    { text: '', value: 'actions', align: 'right', sortable: false },
                ],
                items: [],
                search: '',
                batchSearch: '',
                currentItems: '',
                selectedBatchId: '',
                selectedBatchDeliveryId: '',
                selectedBatch: false,
                selectedBatchDetails: {
                    batch_info: {},
                    documents: []
                },
                retryBatchModal: false,
                retryDocumentModal: false,
                numberOfFailedDocuments: 0,
                retryBatchName: '',
                retryRow: null,
                selectedBatchErrorDocs: [],
                clickBatchStack: [],
                previousBatchName: '',
                currentBatchItems: '',
                todaysDate: '',
                loading: false,
                loadingBatch: false,
                loadingBatches: false,
                allowScrollRequest: false,
                allowBatchScrollRequest: false,
                filterModal: false,
                filterBatchModal: false,
                notesModal: false,
                notesModalView: '',
                notesModalResolvedCheckbox: null,
                notesModalAllowAdd: false,
                notesModalAllowSave: false,
                notesModalAddingNoteInProgress: false,
                notesModalTextarea: '',
                selectedDocumentNotes: [],
                selectedDocument: null,
                expanded: false,
                activeIndex: null,
                batchErrorItems: [],
                batchErrorItemsLoading: false,
                selectedInteractionId: null,
                rpost_delivery_method: NOTICE_MANAGER.RPOST.RPOST_DELIVERY_METHOD,
                filterOptions: {
                    status: {
                        error: {
                            param: 'error',
                            name: 'Error',
                            derived_status: 'error',
                            value: null
                        },
                        in_progress: {
                            param: 'in_progress',
                            name: 'In Progress',
                            derived_status: 'pending',
                            value: null
                        },
                        completed: {
                            param: 'completed',
                            name: 'Completed',
                            derived_status: 'completed',
                            value: null
                        },
                        print_mail: {
                            param: 'print_mail',
                            name: 'Print & Mail',
                            derived_status: 'generated',
                            value: null
                        },
                        retried: {
                          param: 'retried',
                          name: 'Retried',
                          derived_status: 'retried',
                          value: null
                        },
                    },
                    document_type: {
                        delinquency: {
                            param: 'delinquency',
                            name: 'Delinquency',
                            value: null
                        },
                        rent_change: {
                            param: 'rent_change',
                            name: 'Rent Change',
                            value: null
                        },
                    },
                    delivery_method: {
                        email: {
                            param: 'email',
                            name: 'Standard Email',
                            value: null
                        },
                        registered_email: {
                            param: 'registered_email',
                            name: NOTICE_MANAGER.RPOST.RPOST_DELIVERY_METHOD,
                            value: null
                        },
                        first_class: {
                            param: 'first_class',
                            name: 'First Class Mail',
                            value: null
                        },
                        first_class_with_certificate_of_mailing: {
                            param: 'first_class_with_certificate_of_mailing',
                            name: 'First Class Mail with Certificate of Mailing',
                            value: null
                        },
                        certified_mail: {
                            param: 'certified_mail',
                            name: 'Certified Mail',
                            value: null
                        },
                        certified_mail_with_electronic_return_receipt: {
                            param: 'certified_mail_with_electronic_return_receipt',
                            name: 'Certified Mail with Electronic Return Receipt',
                            value: null
                        },
                        no_delivery_requested: {
                            param: 'no_delivery_requested',
                            name: 'No Delivery Requested',
                            value: null
                        }
                    },
                    date_range: {
                        start_date: {
                            param: 'start_date',
                            name: 'Start Date',
                            value: null
                        },
                        end_date: {
                            param: 'end_date',
                            name: 'End Date',
                            value: null
                        }
                    }
                },
                filterOptionsClone: {
                    status: {
                        error: {
                            param: 'error',
                            name: 'Error',
                            derived_status: 'error',
                            value: null
                        },
                        in_progress: {
                            param: 'in_progress',
                            name: 'In Progress',
                            derived_status: 'pending',
                            value: null
                        },
                        completed: {
                            param: 'completed',
                            name: 'Completed',
                            derived_status: 'completed',
                            value: null
                        },
                        print_mail: {
                            param: 'print_mail',
                            name: 'Print & Mail',
                            derived_status: 'generated',
                            value: null
                        },
                      retried: {
                        param: 'retried',
                        name: 'Retried',
                        derived_status: 'retried',
                        value: null
                      },
                    },
                    document_type: {
                        delinquency: {
                            param: 'delinquency',
                            name: 'Delinquency',
                            value: null
                        },
                        rent_change: {
                            param: 'rent_change',
                            name: 'Rent Change',
                            value: null
                        },
                    },
                    delivery_method: {
                        email: {
                            param: 'email',
                            name: 'Standard Email',
                            value: null
                        },
                        registered_email: {
                            param: 'registered_email',
                            name: NOTICE_MANAGER.RPOST.RPOST_DELIVERY_METHOD,
                            value: null
                        },
                        first_class: {
                            param: 'first_class',
                            name: 'First Class Mail',
                            value: null
                        },
                        first_class_with_certificate_of_mailing: {
                            param: 'first_class_with_certificate_of_mailing',
                            name: 'First Class Mail with Certificate of Mailing',
                            value: null
                        },
                        certified_mail: {
                            param: 'certified_mail',
                            name: 'Certified Mail',
                            value: null
                        },
                        certified_mail_with_electronic_return_receipt: {
                            param: 'certified_mail_with_electronic_return_receipt',
                            name: 'Certified Mail with Electronic Return Receipt',
                            value: null
                        },
                        no_delivery_requested: {
                            param: 'no_delivery_requested',
                            name: 'No Delivery Requested',
                            value: null
                        }
                    },
                    date_range: {
                        start_date: {
                            param: 'start_date',
                            name: 'Start Date',
                            value: null
                        },
                        end_date: {
                            param: 'end_date',
                            name: 'End Date',
                            value: null
                        }
                    }
                },
                filterBatchOptions: {
                    contact_type: {
                        primary: {
                            param: 'primary',
                            name: 'Primary',
                            value: null
                        },
                        alternate: {
                            param: 'alternate',
                            name: 'Alternate',
                            value: null
                        },
                        lien_holder: {
                            param: 'lien_holder',
                            name: 'Lien Holder',
                            value: null
                        }
                    },
                    document_status: {
                        bounced: {
                            param: 'bounced',
                            name: 'Bounced',
                            value: null
                        },
                        error: {
                            param: 'error',
                            name: 'Error',
                            value: null
                        },
                        generated: {
                            param: 'generated',
                            name: 'Generated',
                            value: null
                        },
                        pending: {
                            param: 'pending',
                            name: 'Pending',
                            value: null
                        },
                        sent: {
                            param: 'sent',
                            name: 'Sent',
                            value: null
                        },
                        resolved: {
                            param: 'resolved',
                            name: 'Resolved',
                            value: null
                        },
                        retried: {
                            param: 'retried',
                            name: 'Retried',
                            value: null
                        },
                        opened: {
                            param: 'opened',
                            name: 'Opened',
                            value: null
                        },
                        delivered: {
                            param: 'delivered',
                            name: 'Delivered',
                            value: null
                        }
                    }
                },
                filterBatchOptionsClone: {
                    contact_type: {
                        primary: {
                            param: 'primary',
                            name: 'Primary',
                            value: null
                        },
                        alternate: {
                            param: 'alternate',
                            name: 'Alternate',
                            value: null
                        },
                        lien_holder: {
                            param: 'lien_holder',
                            name: 'Lien Holder',
                            value: null
                        }
                    },
                    document_status: {
                        bounced: {
                            param: 'bounced',
                            name: 'Bounced',
                            value: null
                        },
                        error: {
                            param: 'error',
                            name: 'Error',
                            value: null
                        },
                        generated: {
                            param: 'generated',
                            name: 'Generated',
                            value: null
                        },
                        pending: {
                            param: 'pending',
                            name: 'Pending',
                            value: null
                        },
                        sent: {
                            param: 'sent',
                            name: 'Sent',
                            value: null
                        },
                        resolved: {
                            param: 'resolved',
                            name: 'Resolved',
                            value: null
                        },
                        retried: {
                            param: 'retried',
                            name: 'Retried',
                            value: null
                        },
                        opened: {
                            param: 'opened',
                            name: 'Opened',
                            value: null
                        },
                        delivered: {
                            param: 'delivered',
                            name: 'Delivered',
                            value: null
                        }
                    }
                },
            }
        },
        components:{
            HbDatePicker
        },
        created(){
            this.todaysDate = moment.utc().endOf('day').format('YYYY-MM-DD');
 
            if(this.$route.params.document_batch_id){

                this.selectedBatchId = this.$route.params.document_batch_id;

                if(this.$route.params.document_delivery_id){
                    this.selectedBatchDeliveryId = this.$route.params.document_delivery_id;
                }
                this.selectedBatch = true;
            }

            if(this.properties && this.properties.length == 1){
                this.property = this.properties[0];
            } else {
                setTimeout(() => {
                    if(this.properties && this.properties.length == 1){
                        this.property = this.properties[0];
                    }
                }, 1000);
            }
            EventBus.$on('refresh_batches', this.resetFilters);
            EventBus.$on('combine_errors_update', this.downloadCombinedPDF);
        },
        destroyed(){
            EventBus.$off('refresh_batches', this.resetFilters);
            EventBus.$off('combine_errors_update', this.downloadCombinedPDF);
        },
        filters:{
            momentUtcStartOfDay: function (value) {
                if (!value) return '';
                return moment.utc(value).startOf('day').format('MMM DD, YYYY');
            },
            momentUtcEndOfDay: function (value) {
                if (!value) return '';
                return moment.utc(value).endOf('day').format('MMM DD, YYYY');
            },
            momentNotesTime: function (value) {
                if (!value) return '';
                let today = moment.utc().local().startOf('day').calendar();

                if(moment.utc(value).local().startOf('day').calendar() == today){
                    return moment.utc(value).local().format('h:mma');
                } else {
                    return moment.utc(value).local().format('MMM D, YYYY [ @ ] h:mma');
                }
            }
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                properties: 'propertiesStore/filtered',
                getUserData: 'authenticationStore/getUserData',
                hasPermission: 'authenticationStore/rolePermission',
            }),

            canRetry() {
                return this.hasPermission('retry_documents');
            },

            batchHeaders() {

                if(this.selectedBatchDetails.batch_info.delivery_method && this.selectedBatchDetails.batch_info.delivery_method == 'Certified Mail'){
                    return [
                        { text: 'Space', value: 'space', width: '100px', sortable: false },
                        { text: 'Name', value: 'name', width: '160px', sortable: false },
                        { text: 'Contact Type', value: 'contact_type', width: '140px', sortable: false },
                        { text: 'Tracking ID', value: 'tracking_number', width: '160px', sortable: false },
                        { text: 'Document Status', value: 'document_status', width: '180px', sortable: false },
                        { text: 'Details', value: 'status_details', width: '350px', sortable: false },
                        { text: '', value: 'notes', width: '120px', sortable: false },
                        { text: '', value: 'actions', align: 'right', sortable: false },
                    ]
                } else if (this.selectedBatchDetails.batch_info.delivery_method && this.selectedBatchDetails.batch_info.delivery_method.length){
                    return [
                        { text: 'Space', value: 'space', width: '100px', sortable: false },
                        { text: 'Name', value: 'name', width: '160px', sortable: false },
                        { text: 'Contact Type', value: 'contact_type', width: '140px', sortable: false },
                        { text: 'Document Status', value: 'document_status', width: '180px', sortable: false },
                        { text: 'Details', value: 'status_details', width: '350px', sortable: false },
                        { text: '', value: 'notes', width: '120px', sortable: false },
                        { text: '', value: 'actions', align: 'right', sortable: false },
                    ]
                } else {
                    return [
                        { text: 'Space', value: 'space', width: '100px' },
                        { text: 'Name', value: 'name', width: '160px' },
                        { text: 'Contact Type', value: 'contact_type', width: '140px' },
                        { text: 'Document Status', value: 'document_status', width: '180px' },
                        { text: '', value: 'actions', align: 'right', sortable: false },
                    ]
                }
            },
            hasFilters(){
                for (var item in this.filterOptions.status) {
                    if(this.filterOptions.status[item].value){
                        return true;
                    }
                }
                for (var item in this.filterOptions.document_type) {
                    if(this.filterOptions.document_type[item].value){
                        return true;
                    }
                }
                for (var item in this.filterOptions.delivery_method) {
                    if(this.filterOptions.delivery_method[item].value){
                        return true;
                    }
                }
                for (var item in this.filterOptions.date_range) {
                    if(this.filterOptions.date_range[item].value){
                        return true;
                    }
                }
                return false;
            },
            statusFilters(){
                let statusesArray = [];

                for (var item in this.filterOptions.status) {
                    if(this.filterOptions.status[item].value){
                        statusesArray.push(this.filterOptions.status[item].derived_status)
                    }
                }

                return statusesArray;
            },
            documentTypeFilters(){
                let documentTypesArray = [];

                for (var item in this.filterOptions.document_type) {
                    if(this.filterOptions.document_type[item].value){
                        documentTypesArray.push(this.filterOptions.document_type[item].name)
                    }
                }

                return documentTypesArray;
            },
            deliveryMethodFilters(){
                let deliveryMethodArray = [];

                for (var item in this.filterOptions.delivery_method) {
                    if(this.filterOptions.delivery_method[item].value){
                        deliveryMethodArray.push(this.filterOptions.delivery_method[item].name)
                    }
                }

                return deliveryMethodArray;
            },
            hasBatchFilters(){
                for (var item in this.filterBatchOptions.contact_type) {
                    if(this.filterBatchOptions.contact_type[item].value){
                        return true;
                    }
                }
                for (var item in this.filterBatchOptions.document_status) {
                    if(this.filterBatchOptions.document_status[item].value){
                        return true;
                    }
                }
                return false;
            },
            batchContactTypeFilters(){
                let batchContactTypesArray = [];

                for (var item in this.filterBatchOptions.contact_type) {
                    if(this.filterBatchOptions.contact_type[item].value){
                        batchContactTypesArray.push(this.filterBatchOptions.contact_type[item].name)
                    }
                }

                return batchContactTypesArray;
            },
            batchStatusFilters(){
                let batchStatusArray = [];

                for (var item in this.filterBatchOptions.document_status) {
                    if(this.filterBatchOptions.document_status[item].value){
                        batchStatusArray.push(this.filterBatchOptions.document_status[item].name)
                    }
                }

                return batchStatusArray;
            },
            selectedDocumentIsErrorOrResolved(){
                if(this.selectedDocument && ((this.selectedDocument.status && (this.selectedDocument.status == 'error' || this.selectedDocument.status == 'bounced')) || this.selectedDocument.resolved)){
                    return true;
                } else {
                    return false;
                }
            },
            notesModalResolvedStateChanged(){
                if(this.selectedDocument && ((this.selectedDocument.resolved && this.notesModalResolvedCheckbox == null) || !this.selectedDocument.resolved && this.notesModalResolvedCheckbox)){
                    return true;
                } else {
                    return false;
                }
            },
            batchIsEmail(){
                if(this.selectedBatchDetails.batch_info.delivery_type && this.selectedBatchDetails.batch_info.delivery_type == 'email'){
                    return true;
                } else {
                    return false;
                }
            }
        },
        methods:{

            checkIfIntersecting(entries, observer, isIntersecting){
                if (isIntersecting && this.allowScrollRequest) {
                    this.fetchBatches();
                }
            },

            checkBatchIfIntersecting(entries, observer, isIntersecting){
                if (isIntersecting && this.allowBatchScrollRequest) {
                    this.fetchBatchDetails();
                }
            },

            fetchBatches(){

                this.loadingBatches = true;

                let data = {
                    property_id: this.property.id,
                    page: Math.ceil((this.items.length + 20) / 20),
                    count: 20
                }

                if(this.statusFilters.length){
                    data.status = this.statusFilters;
                }

                if(this.documentTypeFilters.length){
                    data.document_type = this.documentTypeFilters;
                }

                if(this.deliveryMethodFilters.length){
                    data.delivery_method = this.deliveryMethodFilters;
                }

                if(this.filterOptions.date_range.start_date.value){
                    data.start_date = moment.utc(this.filterOptions.date_range.start_date.value).startOf('day').format();
                }

                if(this.filterOptions.date_range.end_date.value){
                    data.end_date = moment.utc(this.filterOptions.date_range.end_date.value).endOf('day').format();
                }

                if(this.search){
                    data.template_name = this.search;
                }

                // console.log(data);

                setTimeout(() => {
                    api.get(this, api.DOCUMENT_BATCHES, data).then(r => {
                        // console.log(JSON.stringify(r));
                        if(r.document_batches && r.document_batches.length < 20){
                            this.allowScrollRequest = false;
                        } else {
                            this.allowScrollRequest = true;
                        }

                        let tempItems = this.items;
                        let tempArray = r.document_batches;
                        this.items = tempItems.concat(tempArray);
                        this.loadingBatches = false;
                    }).catch(err => {
                        this.showMessageNotification({ description: err });

                        this.allowScrollRequest = false;
                        this.loadingBatches = false;
                    });
                }, 1000);

            },

            fetchBatchDetails(){

                this.loadingBatch = true;


                if(this.$route.params.document_batch_id){
                        this.selectedBatchId = this.$route.params.document_batch_id;

                        if(this.$route.params.document_delivery_id){
                            this.selectedBatchDeliveryId = this.$route.params.document_delivery_id;
                        }
                        this.selectedBatch = true;
                }

                if(!this.selectedBatchDetails.batch_info.document_batch_id){
                    this.loading = true;
                }

                let data = {
                    document_batch_id: this.selectedBatchId,
                    document_delivery_id: this.selectedBatchDeliveryId ? this.selectedBatchDeliveryId : null,
                    page: Math.ceil((this.selectedBatchDetails.documents.length + 20) / 20),
                    count: 20
                }

                if(this.batchContactTypeFilters.length){
                    data.contact_type = this.batchContactTypeFilters;
                }

                if(this.batchStatusFilters.length){
                    data.status = this.batchStatusFilters;
                }

                if(this.batchSearch){
                    data.unit = this.batchSearch; // this param needs to change later when backend is able to handle searching unit + name combined
                }

                // console.log(data);
                return new Promise((resolve, reject) => {
                setTimeout(() => {
                    api.get(this, api.DOCUMENT_BATCH, data).then(r => {
                        // console.log(JSON.stringify(r));
                        this.selectedBatchDetails.batch_info = r.batch_info;

                        if(this.property && this.property.id && this.selectedBatchDetails.batch_info.property_id && (this.property.id !== this.selectedBatchDetails.batch_info.property_id)){
                            this.goBack();
                            return;
                        }

                        if(!this.property.id){
                            this.property = this.properties.filter(property => {
                                return property.id == this.selectedBatchDetails.batch_info.property_id;
                            })[0];
                        }

                        if(r.documents && r.documents.length < 20){
                            this.allowBatchScrollRequest = false;
                        } else {
                            this.allowBatchScrollRequest = true;
                        }

                        let tempBatchItems = this.selectedBatchDetails.documents;
                        let tempBatchArray = r.documents;
                        this.selectedBatchDetails.documents = tempBatchItems.concat(tempBatchArray);

                        this.loading = false;
                        this.loadingBatch = false;

                        resolve();

                    }).catch(err => {
                        this.showMessageNotification({ description: err });

                        this.goBack();
                    });
                }, 1000); 
            });

            },

            rowClicked(row){

                this.batchSearch = '';
                this.allowBatchScrollRequest = false;
                this.selectedBatchDetails = {
                    batch_info: {},
                    documents: []
                }
                console.log("ROW CLICKED", row)
                if(row && row.document_batch_id && !row.document_delivery_id){
                    this.$router.push('/notice-manager/' + row.document_batch_id);
                    this.selectedBatchId = row.document_batch_id;
                    this.selectedBatch = true;
                } else if(row && row.document_batch_id && row.document_delivery_id){
                    this.$router.push('/notice-manager/' + row.document_batch_id + '/' + row.document_delivery_id);
                    this.selectedBatchId = row.document_batch_id;
                    this.selectedBatchDeliveryId = row.document_delivery_id;
                    this.selectedBatch = true;
                } else {
                    this.showMessageNotification({ description: 'Invalid batch or an error has occured.' });
                }

                
                console.log(this);
            },
          goToOriginalRow(row){

            this.batchSearch = '';
            this.allowBatchScrollRequest = false;
            let data = {
              document_batch_id: row.document_batch_id,
              document_delivery_id: row.document_delivery_id,
              previousBatchName: row.template.name
            }
            this.previousBatchName = row.template.name
            this.clickBatchStack.push(data)
            console.log("clickBatchStack", this.clickBatchStack)

            this.selectedBatchDetails = {
              batch_info: {},
              documents: []
            }
            let retry_batch = row?.retry_batch_id
            let document_delivery_id = row?.retry_document_delivery_id

            console.log("ROW CLICKED", row)
            if(retry_batch && !document_delivery_id){
              this.$router.push('/notice-manager/' + retry_batch);
              this.selectedBatchId = retry_batch;
              this.selectedBatch = true;
            } else if(row && retry_batch && document_delivery_id){
              this.$router.push('/notice-manager/' + retry_batch + '/' + document_delivery_id);
              this.selectedBatchId = retry_batch;
              this.selectedBatchDeliveryId = document_delivery_id;
              this.selectedBatch = true;
            } else {
              this.showMessageNotification({ description: 'Invalid batch or an error has occured.' });
            }

            setTimeout(() => {
              this.fetchBatchDetails();
            }, 100);




            console.log(this);
          },
          goToPreviousRow(){

            this.batchSearch = '';
            this.allowBatchScrollRequest = false;
            console.log("Previous Row Shift", this.clickBatchStack)
            let row = this.clickBatchStack.shift()
            if (this.clickBatchStack.length) this.previousBatchName = row.previousBatchName
            else this.previousBatchName = null

            this.selectedBatchDetails = {
              batch_info: {},
              documents: []
            }
            let retry_batch = row?.document_batch_id
            let document_delivery_id = row?.document_delivery_id

            console.log("ROW CLICKED", row)
            if(retry_batch && !document_delivery_id){
              this.$router.push('/notice-manager/' + retry_batch);
              this.selectedBatchId = retry_batch;
              this.selectedBatch = true;
            } else if(row && retry_batch && document_delivery_id){
              this.$router.push('/notice-manager/' + retry_batch + '/' + document_delivery_id);
              this.selectedBatchId = retry_batch;
              this.selectedBatchDeliveryId = document_delivery_id;
              this.selectedBatch = true;
            } else {
              this.showMessageNotification({ description: 'Invalid batch or an error has occured.' });
            }

            setTimeout(() => {
              this.fetchBatchDetails();
            }, 100);




            console.log(this);
          },

            goBack(){
                this.loading = true;
                this.resetBatchFilters('stop');
                this.selectedBatchId = '';
                this.selectedBatchDeliveryId = '';

                this.batchSearch = '';
                this.$router.push('/notice-manager');
                this.allowScrollRequest = false;
                this.items = [];
                this.fetchBatches();

                setTimeout(() => {
                    this.selectedBatch = false;
                    if(!this.items.length){
                        this.fetchBatches();
                    }
                }, 2000);
            },

            async getCombinedDocuments(id, batchId){

                try {
                    const downloadedFile = await api.put(this, api.UPLOADS + id + '/download');
                    console.log(downloadedFile);
                    const fileContent = downloadedFile.file;
                    var buffer = Buffer.from(fileContent.Body.data);
                    var blob = new Blob([buffer], { type: downloadedFile.mimetype });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = downloadedFile.name;
                    link.click();
                    this.items.find(i => i.document_batch_id === batchId).last_downloaded_on = downloadedFile.last_downloaded;
                    this.items.find(i => i.document_batch_id === batchId).last_downloaded_by = {
                        id: downloadedFile.last_downloaded_by.id,
                        last_name: downloadedFile.last_downloaded_by.last_name,
                        first_name: downloadedFile.last_downloaded_by.first_name,
                    }
                } catch(err) {
                    this.showMessageNotification({ description: err });
                }

                console.log(this);

            },

            async downloadErrorDocs(row, batch_id) {

                this.selectedBatchDetails = {
                        batch_info: {},
                        documents: []
                }

                if(row && row.document_batch_id && !row.document_delivery_id){
                    this.selectedBatchId = row.document_batch_id;
                    await this.fetchBatchDetails();
                } else if(row && row.document_batch_id && row.document_delivery_id){
                    this.selectedBatchId = row.document_batch_id;
                    this.selectedBatchDeliveryId = row.document_delivery_id;
                    await this.fetchBatchDetails();
                } else {
                    this.showMessageNotification({ description: 'Invalid batch or an error has occured.' });
                    return;
                }

                while(this.selectedBatchDetails.documents.length % 20 == 0) {
                    let t = this.selectedBatchDetails.documents.length;
                    await this.fetchBatchDetails();
                    if(t == this.selectedBatchDetails.documents.length) {
                        break;
                    }
                }

                console.log(this.selectedBatchDetails);

                try {
                    await api.put(this, api.UPLOADS + '/download/errors', this.selectedBatchDetails);
                    /*
                    console.log(downloadedFile);
                    const fileContent = downloadedFile.file;
                    var buffer = Buffer.from(fileContent.Body.data);
                    var blob = new Blob([buffer], { type: downloadedFile.mimetype });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = downloadedFile.name;
                    link.click(); */
                }
                catch(err) {
                    this.showMessageNotification({ description: err });
                }

            },

            async downloadCombinedPDF(payload) {
                try {
                    console.log("downloadCombinedPDF");
                    const { file, name, mimetype } = payload;
                    console.log(file);
                    const fileContent = file;
                    var buffer = Buffer.from(fileContent.Body.data);
                    var blob = new Blob([buffer], { type: mimetype });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name;
                    link.click();
                } catch(err) {
                    this.showMessageNotification({ description: err });
                }
            },
          confirmRetryBatchModal(item) {
              console.log("ITEM BATCH", item)
            this.numberOfFailedDocuments = item?.interaction_status_count?.error;
            this.retryBatchName = item?.template?.name;
            this.retryRow = item
            this.retryBatchModal = true;
          },
          confirmRetryDocumentModal(item) {
              console.log("ITEM", item)
            this.numberOfFailedDocuments = 1;
            this.retryBatchName = item?.batch_info?.template?.name;
            this.retryRow = item
            this.retryDocumentModal = true;
          },
            async retryBatchSend() {

                //console.log(row);
              let row = this.retryRow
              this.retryBatchModal = false;

                this.batchSearch = '';
                this.allowBatchScrollRequest = false;

                var data = {
                    batch_info: this.selectedBatchDetails.batch_info,
                    documents: []
                }
                this.selectedBatchDetails = {
                    batch_info: {},
                    documents: []
                }

                if(row && row.document_batch_id && !row.document_delivery_id){
                    this.selectedBatchId = row.document_batch_id;
                    await this.fetchBatchDetails();
                } else if(row && row.document_batch_id && row.document_delivery_id){
                    this.selectedBatchId = row.document_batch_id;
                    this.selectedBatchDeliveryId = row.document_delivery_id;
                    await this.fetchBatchDetails();
                } else {
                    this.showMessageNotification({ description: 'Invalid batch or an error has occured.' });
                    return;
                }

                while(this.selectedBatchDetails.documents.length % 20 == 0) {
                    let t = this.selectedBatchDetails.documents.length;
                    await this.fetchBatchDetails();
                    if(t == this.selectedBatchDetails.documents.length) {
                        break;
                    }
                }

                data.batch_info = this.selectedBatchDetails.batch_info;
                data.documents = this.selectedBatchDetails.documents;

                try {
                  await  api.post(this, api.INTERACTIONS + 'retry-send', data);
                  const payload = {
                    type: 'document_retry_update',
                    title: 'Retrying Document',
                    persistent: true,
                    loading: true,
                    description: 'Please wait while we retry sending your documents. This process may take a few minutes.'
                  }
                  this.showMessageNotification(payload)
                } catch (err) {
                  this.showMessageNotification({type: "error", description: `Failed to Retry Documents.`});
                }
            },
          async retryDocumentSend() {

            //console.log(row);
            this.retryDocumentModal = false;
            let row = this.retryRow

            this.batchSearch = '';
            this.allowBatchScrollRequest = false;

            var data = {
              batch_info: this.selectedBatchDetails.batch_info,
              documents: []
            }
            data.documents = [row];

            try {
              await  api.post(this, api.INTERACTIONS + 'retry-send', data);
              const payload = {
                type: 'document_retry_update',
                title: 'Retrying Documents',
                persistent: true,
                loading: true,
                description: 'Please wait while we retry sending your document. This process may take a few minutes.'
              }
              this.showMessageNotification(payload)
            } catch (err) {
              this.showMessageNotification({type: "error", description: `Failed to Retry Documents.`});
            }
          },

            async getIndividualDocument(id){
                try {
                    console.log()
                    const downloadedFile = await api.put(this, api.UPLOADS + id + '/download?setDestination=true');
                    // console.log(downloadedFile);
                    const fileContent = downloadedFile.file;
                    var buffer = Buffer.from(fileContent.Body.data);
                    var blob = new Blob([buffer], { type: downloadedFile.mimetype });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = downloadedFile.name;
                    link.click();
                } catch(err) {
                    this.showMessageNotification({ description: err });
                }
            },

            async getMetaDocument(id){
                try {
                    const downloadedFile = await api.get(this, api.PROPERTIES + this.property.id + '/files/' + id);
                    // console.log(downloadedFile);
                    if(downloadedFile.file && downloadedFile.file.download_url){
                        window.open(downloadedFile.file.download_url);
                    } else {                       
                        this.showMessageNotification({ description: 'An unexpected error has occurred. Please try again later or contact Customer Success if the error persists.' });
                    }
                } catch(err) {
                    this.showMessageNotification({ description: err });
                }
            },
            
            resetFilters(){
                this.filterOptions = {
                    status: {
                        error: {
                            param: 'error',
                            name: 'Error',
                            derived_status: 'error',
                            value: null
                        },
                        in_progress: {
                            param: 'in_progress',
                            name: 'In Progress',
                            derived_status: 'pending',
                            value: null
                        },
                        completed: {
                            param: 'completed',
                            name: 'Completed',
                            derived_status: 'completed',
                            value: null
                        },
                        print_mail: {
                            param: 'print_mail',
                            name: 'Print & Mail',
                            derived_status: 'generated',
                            value: null
                        },
                      retried: {
                        param: 'retried',
                        name: 'Retried',
                        derived_status: 'retried',
                        value: null
                      },
                    },
                    document_type: {
                        delinquency: {
                            param: 'delinquency',
                            name: 'Delinquency',
                            value: null
                        },
                        rent_change: {
                            param: 'rent_change',
                            name: 'Rent Change',
                            value: null
                        },
                    },
                    delivery_method: {
                        email: {
                            param: 'email',
                            name: 'Standard Email',
                            value: null
                        },
                        registered_email: {
                            param: 'registered_email',
                            name: NOTICE_MANAGER.RPOST.RPOST_DELIVERY_METHOD,
                            value: null
                        },
                        first_class: {
                            param: 'first_class',
                            name: 'First Class Mail',
                            value: null
                        },
                        first_class_with_certificate_of_mailing: {
                            param: 'first_class_with_certificate_of_mailing',
                            name: 'First Class Mail with Certificate of Mailing',
                            value: null
                        },
                        certified_mail: {
                            param: 'certified_mail',
                            name: 'Certified Mail',
                            value: null
                        },
                        certified_mail_with_electronic_return_receipt: {
                            param: 'certified_mail_with_electronic_return_receipt',
                            name: 'Certified Mail with Electronic Return Receipt',
                            value: null
                        },
                        no_delivery_requested: {
                            param: 'no_delivery_requested',
                            name: 'No Delivery Requested',
                            value: null
                        }
                    },
                    date_range: {
                        start_date: {
                            param: 'start_date',
                            name: 'Start Date',
                            value: null
                        },
                        end_date: {
                            param: 'end_date',
                            name: 'End Date',
                            value: null
                        }
                    }
                }

                if(this.filterModal){
                    this.filterModal = false;
                }

                this.allowScrollRequest = false;
                this.items = [];
                this.fetchBatches();
            },

            resetBatchFilters(condition){
                this.filterBatchOptions = {
                    contact_type: {
                        primary: {
                            param: 'primary',
                            name: 'Primary',
                            value: null
                        },
                        alternate: {
                            param: 'alternate',
                            name: 'Alternate',
                            value: null
                        },
                        lien_holder: {
                            param: 'lien_holder',
                            name: 'Lien Holder',
                            value: null
                        }
                    },
                    document_status: {
                        bounced: {
                            param: 'bounced',
                            name: 'Bounced',
                            value: null
                        },
                        error: {
                            param: 'error',
                            name: 'Error',
                            value: null
                        },
                        generated: {
                            param: 'generated',
                            name: 'Generated',
                            value: null
                        },
                        pending: {
                            param: 'pending',
                            name: 'Pending',
                            value: null
                        },
                        sent: {
                            param: 'sent',
                            name: 'Sent',
                            value: null
                        },
                        resolved: {
                            param: 'resolved',
                            name: 'Resolved',
                            value: null
                        },
                        retried: {
                            param: 'retried',
                            name: 'Retried',
                            value: null
                        },
                        opened: {
                            param: 'opened',
                            name: 'Opened',
                            value: null
                        },
                        delivered: {
                            param: 'delivered',
                            name: 'Delivered',
                            value: null
                        }
                    }
                };

                if(this.filterBatchModal){
                    this.filterBatchModal = false;
                }

                this.allowBatchScrollRequest = false;
                this.selectedBatchDetails.documents = [];

                if(condition !== 'stop'){
                    this.fetchBatchDetails();
                }
            },

            goFilter(type, param){
                if(type == 'status'){
                    this.filterOptionsClone.status[param].value = null;
                } else if(type == 'document_type'){
                    this.filterOptionsClone.document_type[param].value = null;
                } else if(type == 'delivery_method'){
                    this.filterOptionsClone.delivery_method[param].value = null;
                } else if(type == 'date_range'){
                    this.filterOptionsClone.date_range['start_date'].value = null;
                    this.filterOptionsClone.date_range['end_date'].value = null;
                }
                this.filterOptions = JSON.parse(JSON.stringify(this.filterOptionsClone));
                this.filterModal = false;
                this.allowScrollRequest = false;
                this.items = [];
                this.fetchBatches();
            },

            goBatchFilter(type, param){
                if(type == 'contact_type'){
                    this.filterBatchOptionsClone.contact_type[param].value = null;
                } else if(type == 'document_status'){
                    this.filterBatchOptionsClone.document_status[param].value = null;
                }
                this.filterBatchOptions = JSON.parse(JSON.stringify(this.filterBatchOptionsClone));
                this.filterBatchModal = false;
                this.allowBatchScrollRequest = false;
                this.selectedBatchDetails.documents = [];
                this.fetchBatchDetails();
            },

            async openNotesModal(item){
               // console.log('interaction_id = ' + item.interaction_id);
                if(item && item.interaction_id){
                    this.selectedDocument = item;
                    this.selectedInteractionId = item.interaction_id;
                } else {
                    return;                  
                    this.showMessageNotification({ description: 'Something went wrong. Please contact Customer Success if the issue persists.' });
                }

                if(this.selectedDocument.resolved){
                    this.notesModalResolvedCheckbox = true;
                } else {
                    this.notesModalResolvedCheckbox = false;
                }

                if(item.note_count > 0){
                    try {
                        let r = await api.get(this, api.INTERACTIONS + item.interaction_id + '/notes');
                        // console.log(JSON.stringify(r.notes));

                        this.selectedDocumentNotes = r.notes;

                        this.notesModalView = 'view';
                        this.notesModalAllowAdd = true;

                        this.notesModal = true;
                    } catch(err) {
                        this.showMessageNotification({ description: err });
                    }
                } else {
                    this.notesModalView = 'add';
                    this.notesModalAllowSave = true;
                    this.notesModalAddingNoteInProgress = true;

                    this.notesModal = true;
                }
            },

            closeNotesModal(){
                this.selectedInteractionId = null;
                this.activeIndex = null;
                this.selectedDocument = null;
                this.notesModalView = '';
                this.notesModalResolvedCheckbox = null;
                this.notesModalAllowAdd = false;
                this.notesModalAddingNoteInProgress = false;
                this.notesModalTextarea = '';
                this.selectedDocumentNotes = [];
                this.notesModal = false;
                this.notesModalAllowSave = false;
            },

            notesModalAdd(){
                this.activeIndex = null;
                this.notesModalView = 'add';
                this.notesModalAllowAdd = false;
                this.notesModalAddingNoteInProgress = true;
                this.notesModalAllowSave = true;
            },

            notesModalSave(){
 
                if(this.notesModalAddingNoteInProgress){
                    // add new note and save resolved boolean if the checkbox has been been changed

                    this.$validator.validate('note').then(valid => {
                        if (!valid) {
                            this.showMessageNotification({ description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                            return;
                        } else {

                            var data = {
                                content: this.notesModalTextarea,
                            }
                            
                            api.post(this, api.INTERACTIONS + this.selectedInteractionId + '/notes', data).then(r => {
                                // console.log(r);
                                if(r){

                                    this.selectedBatchDetails.documents.find(i => i.interaction_id === this.selectedInteractionId).note_count++;

                                    if(this.notesModalResolvedStateChanged){
                                        
                                        let resolvedInfo = {
                                            resolved: this.notesModalResolvedCheckbox ? true : false,
                                            content: this.selectedDocument.status_details
                                        }

                                        api.post(this, api.INTERACTIONS + this.selectedInteractionId + '/resolved_notes', resolvedInfo).then(r => {
                                            api.get(this, api.INTERACTIONS + this.selectedInteractionId + '/notes').then(r => {
                                                this.selectedDocumentNotes = r.notes;
                                            }).catch(err => {
                                                this.showMessageNotification({ description: err });
                                            });
                                        });

                                        let dataResolved = {
                                            resolved: this.notesModalResolvedCheckbox ? true : false,
                                        };
                                        
                                        api.put(this, api.INTERACTIONS + this.selectedInteractionId, dataResolved).then(r => {

                                            this.selectedBatchDetails.documents.find(i => i.interaction_id === this.selectedInteractionId).resolved = dataResolved.resolved;
                                            
                                            this.showMessageNotification({ type: 'success', description: 'Note has been successfully added and document resolved status has been successfully updated.' });
                                        }).catch(err => {
                                            this.showMessageNotification({ description: err });
                                        });

                                    } else {
                                        this.showMessageNotification({ type: 'success', description: 'Note has been successfully added.' });
                                    }

                                }

                                this.notesModalAllowSave = false;
                                this.notesModalAllowAdd = true;
                                this.notesModalTextarea = '';
                                this.notesModalAddingNoteInProgress = false;
                                this.activeIndex = null;
                                this.notesModalView = 'view';

                                api.get(this, api.INTERACTIONS + this.selectedInteractionId + '/notes').then(r => {
                                    // console.log(JSON.stringify(r.notes));

                                    this.selectedDocumentNotes = r.notes;
                                }).catch(err => {
                                    this.showMessageNotification({ description: err });
                                });
                            }).catch(err => {
                                this.showMessageNotification({ description: err });
                            });

                        }
                    });
                } else {
                    // or just save resolved status
                    
                    if(this.notesModalResolvedStateChanged){

                        let resolvedInfo = {
                            resolved: this.notesModalResolvedCheckbox ? true : false,
                            content: this.selectedDocument.status_details
                        }

                        api.post(this, api.INTERACTIONS + this.selectedInteractionId + '/resolved_notes', resolvedInfo).then(r => {

                                api.get(this, api.INTERACTIONS + this.selectedInteractionId + '/notes').then(r => {
                                        this.selectedDocumentNotes = r.notes;
                                    }).catch(err => {
                                        this.showMessageNotification({ description: err });
                                    });
                        }).catch(err => {
                            this.showMessageNotification({ description: err });
                        });

                        let dataResolved = {
                            resolved: this.notesModalResolvedCheckbox ? true : false,
                        };

                        api.put(this, api.INTERACTIONS + this.selectedInteractionId, dataResolved).then(r => {
                            this.selectedBatchDetails.documents.find(i => i.interaction_id === this.selectedInteractionId).resolved = dataResolved.resolved;

                            this.showMessageNotification({ type: 'success', description: 'Document resolved status has been successfully updated.' });
                        }).catch(err => {
                            this.showMessageNotification({ description: err });
                        });
                    }

                    this.activeIndex = null;
                    this.notesModalAllowSave = false;
                    this.notesModalAllowAdd = true;
                    this.notesModalTextarea = '';
                    this.notesModalAddingNoteInProgress = false;
                    this.notesModalView = 'view';
                }
            },

            searchBatches:  _.debounce(function() {
                this.allowScrollRequest = false;
                this.items = [];
                this.fetchBatches();
            }, 1000),

            searchBatch:  _.debounce(function() {
                this.allowBatchScrollRequest = false;
                this.selectedBatchDetails.documents = [];
                this.fetchBatchDetails();
            }, 1000),

            async getErrors(item){
                this.batchErrorItemsLoading = true;
                this.batchErrorItems = [];

                try {
                    let data = {
                        document_batch_id: item.document_batch_id,
                        document_delivery_id: item.document_delivery_id,
                        page: 1,
                        count: 3
                    };
                    let r = await api.get(this, api.DOCUMENT_BATCH_ERRORS, data);

                    if(r){
                        this.batchErrorItems = r.batch_errors
                        this.batchErrorItemsLoading = false;
                    }

                } catch(err) {
                    this.showMessageNotification({ description: err });
                    this.batchErrorItemsLoading = false;
                }
            }
        },
        watch: {
            property(){
                this.allowScrollRequest = false;
                this.search = '';
                this.items = [];
                this.fetchBatches();
            },
            filterModal(){
                if(this.filterModal){
                    this.filterOptionsClone = JSON.parse(JSON.stringify(this.filterOptions));
                }
            },
            filterBatchModal(){
                if(this.filterBatchModal){
                    this.filterBatchOptionsClone = JSON.parse(JSON.stringify(this.filterBatchOptions));
                }
            },
            selectedBatch(){
                if(this.selectedBatch){
                    this.fetchBatchDetails();
                } else if(!this.selectedBatch){
                    this.clickBatchStack = []
                    this.batchSearch = '';
                    this.loading = false;
                    this.allowBatchScrollRequest = false;
                    this.selectedBatchDetails = {
                        batch_info: {},
                        documents: []
                    }
                }
            },
            '$route'(){
                if(this.$route.name === 'noticeManagerBatchViewDirect' || this.$route.name === 'noticeManagerBatchViewDirectBatchIdOnly'){
                    this.selectedBatch = true;
                } else if(this.$route.name == 'noticeManager'){
                    this.loading = true;
                    setTimeout(() => {
                        this.selectedBatch = false;
                        this.loading = false;
                    }, 2000);
                }
            }
        }
    }



</script>

<style scoped>

    .section-content {
      padding-right: 0px !important;
    }

    .dlpc-content-section-wrapper {
        padding-right: 20px;
    }

    .dashed-underline {
        border-bottom: 1px dashed #101318;
        padding-bottom: 2px;
        display: inline;
    }

    .dlpc-display-inline {
        display: inline;
    }

    .dlpc-table-footer-wrapper {
        min-height: 50px;
        border: 1px solid #DFE3E8;
        border-top: none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .dlpc-overflow-handler {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dlpc-row-custom-margin-top {
        margin-top: 1px;
    }

    .dlpc-notes-modal-content {
        position: relative;
        min-height: 400px;
    }

    .dlpc-notes-modal-notes {
        background: #FFFFFF;
    }

    .dlpc-loading {
        position: relative;
        margin: 22% auto 0;
        left: 47%;
    }

</style>
