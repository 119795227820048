<template>
    <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
        <notices-list  :key="report_key"/> 
    </div>
</template>

<script type="text/babel">
    import { EventBus } from '../../EventBus.js';
    import NoticesList from './NoticesList.vue';
    
    export default {
        name: "NoticeManagerIndex",
        data() {
            return {
                report_key:0
            }
        },
        components:{
            NoticesList
        },
        created(){
            EventBus.$on('refetch_data', () => this.fetchData());
        },
        destroyed(){
            EventBus.$off('refetch_data', () => this.fetchData());
        },
        methods:{
            fetchData(){
                this.report_key++;
            }
        }
    }
</script>
